import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Avatar, Menu, MenuItem, Box, ListItemIcon } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'; 
import Logo from '../images/Logo.png';

const Navbar = () => {
  const { isAuthenticated, user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  const stringAvatar = (name) => {
    if (!name) return { sx: { bgcolor: '#000' }, children: '?' }; 
    const initials = name.split(' ').map(word => word[0]).join('').toUpperCase();
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initials,
    };
  };

  return (
    <AppBar position="static" style={{ backgroundColor: '#000', color: '#fff' }}>
      <Toolbar>
      <img src={Logo} alt="Logo" style={{ width: '60px' }} />
        <Typography variant="h6" style={{ flexGrow: 1 }}>
        
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            Reviewmee
          </Link>
        </Typography>
        <Button color="inherit" component={Link} to="/about">
          About
        </Button>
        <Button color="inherit" component={Link} to="/contact">
          Contact Us
        </Button>
        {isAuthenticated ? (
          <>
            <Button color="inherit" component={Link} to="/post/create">
              <PostAddIcon style={{ marginRight: '5px' }} />
              Create Post
            </Button>
            <Box display="flex" alignItems="center">
              <Avatar {...stringAvatar(user?.name)} onClick={handleMenuOpen} style={{ cursor: 'pointer' }} />
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem disabled>
                <Typography variant="body2">Welcome, {user?.name}</Typography>
              </MenuItem>
              <MenuItem component={Link} to="/profile" onClick={handleMenuClose}>
                <ListItemIcon>
                  <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                Profile
              </MenuItem>
              <MenuItem component={Link} to="/post/manage" onClick={handleMenuClose}>
                <ListItemIcon>
                  <ManageAccountsIcon fontSize="small" />
                </ListItemIcon>
                Manage Posts
              </MenuItem>
              <MenuItem component={Link} to="/settings" onClick={handleMenuClose}>
                <ListItemIcon>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem onClick={() => { handleLogout(); handleMenuClose(); }}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Button color="inherit" component={Link} to="/login">
              Login
            </Button>
            <Button color="inherit" component={Link} to="/register">
              Register
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
