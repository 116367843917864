import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Alert, CircularProgress } from '@mui/material';
import axios from 'axios';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setMessage('Please enter a valid email address');
      setSuccess(false);
      return;
    }

    setLoading(true);
    setMessage('');
    setSuccess(false);

    try {
      const response = await axios.post('http://localhost:5000/api/auth/forgot-password', { email });
      setMessage(response.data.msg);
      setSuccess(true);
      setEmail('');
    } catch (error) {
      console.error('Error sending reset email:', error); // Log the full error for debugging
      const errorMsg = error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg
        : 'Error sending reset email';
      setMessage(errorMsg);
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Forgot Password
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          type="email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Send Reset Link'}
        </Button>
      </form>
      {message && (
        <Alert severity={success ? 'success' : 'error'} style={{ marginTop: '20px' }}>
          {message}
        </Alert>
      )}
    </Container>
  );
};

export default ForgotPasswordPage;
