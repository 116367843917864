import React, { useState, useContext } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import CreatePostHero from '../images/create_hero.webp'; 

const CreatePostPage = () => {
  const { isAuthenticated, user } = useContext(AuthContext);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      setMessage('You must be logged in to create a post');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    formData.append('author', user?.name);
    if (image) {
      formData.append('image', image);
    }

    try {
      const response = await axios.post('http://localhost:5000/api/posts', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      setMessage('Post created successfully!');
      setTitle('');
      setContent('');
      setImage(null);
      navigate('/post/manage'); // Navigate to post management page
    } catch (error) {
      const errorMsg = error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg
        : 'Error creating post';
      setMessage(errorMsg);
    }
  };

  return (
    <Container>
      <Box
        sx={{
          height: '50vh',
          background: `url(${CreatePostHero}) no-repeat center center/cover`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          textAlign: 'center',
          padding: '0 20px',
          marginBottom: '40px',
        }}
      >
      </Box>
      <Typography variant="h4"  gutterBottom>
        Create New Post
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Title"
          fullWidth
          margin="normal"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          label="Content"
          fullWidth
          multiline
          rows={4}
          margin="normal"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setImage(e.target.files[0])}
          style={{ margin: '20px 0' }}
        />
        <Button type="submit" variant="contained" style={{ color: '#fff', backgroundColor: '#000' }}>
          Create Post
        </Button>
      </form>
      {message && <Typography style={{ marginTop: '20px' }}>{message}</Typography>}
    </Container>
  );
};

export default CreatePostPage;
