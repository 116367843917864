import React, { useContext, useState } from 'react';
import { Container, Typography, Avatar, Grid, Box, TextField, Button } from '@mui/material';
import AuthContext from '../context/AuthContext';
import EmailIcon from '@mui/icons-material/Email';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import axios from 'axios';

const ProfilePage = () => {
  const { user, setUser } = useContext(AuthContext);
  const [name, setName] = useState(user?.name || '');
  const [email, setEmail] = useState(user?.email || '');
  const [message, setMessage] = useState('');

  if (!user) {
    return <Typography>Loading...</Typography>;
  }

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  const stringAvatar = (name) => {
    const initials = name.split(' ').map(word => word[0]).join('').toUpperCase();
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initials,
    };
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();

    console.log('Updating profile for user ID:', user._id);

    try {
      const response = await axios.put(`http://localhost:5000/api/users/${user._id}`, {
        name,
        email,
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });

      setUser(response.data);
      setMessage('Profile updated successfully!');
    } catch (error) {
      if (error.response) {
        setMessage(`Error updating profile: ${error.response.data.msg || error.response.statusText}`);
      } else {
        setMessage('Error updating profile: Network or server issue');
      }
    }
  };

  return (
    <Container>
      <Box display="flex" alignItems="center" flexDirection="column" mb={4}>
        <Avatar {...stringAvatar(user.name)} style={{ width: 100, height: 100, marginBottom: 16 }} />
        <Typography variant="h4" gutterBottom>
          {user.name}
        </Typography>
      </Box>
      <form onSubmit={handleUpdateProfile}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" mb={2}>
              <AccountCircleIcon fontSize="large" style={{ marginRight: 8 }} />
              <TextField
                label="Name"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <EmailIcon fontSize="large" style={{ marginRight: 8 }} />
              <TextField
                label="Email"
                type="email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Box>
            <Button type="submit" variant="contained" color="primary" style={{ color: '#fff', backgroundColor: '#000' }}>
              Update Profile
            </Button>
          </Grid>
        </Grid>
      </form>
      {message && (
        <Box mt={2}>
          <Typography>{message}</Typography>
        </Box>
      )}
    </Container>
  );
};

export default ProfilePage;
