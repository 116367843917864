import React, { useContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import theme from './theme';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import DiscussionsPage from './pages/DiscussionsPage';
import ProfilePage from './pages/ProfilePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import Announcement from './components/Announcement';
import Resource from './components/Resource';
import CreatePostPage from './pages/CreatePostPage';
import PostManagementPage from './pages/PostManagementPage';
import About from './pages/About';
import Contact from './pages/Contact';
import AuthContext from './context/AuthContext';

const App = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Navbar />
          <main style={{ flex: '1 0 auto' }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/discussions" element={<DiscussionsPage />} />
              <Route path="/profile" element={isAuthenticated ? <ProfilePage /> : <Navigate to="/login" />} />
              <Route path="/login" element={!isAuthenticated ? <LoginPage /> : <Navigate to="/" />} />
              <Route path="/register" element={!isAuthenticated ? <RegisterPage /> : <Navigate to="/" />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
              <Route path="/announcements/:id" element={<Announcement />} />
              <Route path="/resources/:id" element={<Resource />} />
              <Route path="/post/create" element={isAuthenticated ? <CreatePostPage /> : <Navigate to="/login" />} />
              <Route path="/post/manage" element={isAuthenticated ? <PostManagementPage /> : <Navigate to="/login" />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
