import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import PublicTransportImage from '../images/public_transport.webp';
import SmallBusinessImage from '../images/small_business.webp';
import EnvironmentalProtectionImage from '../images/environmental_protection.webp';
import HealthcareImage from '../images/healthcare.webp';
import FinancialAssistanceImage from '../images/financial_assistance.webp';
import HeroImage from '../images/hero.webp'; 


const Home = () => {
  return (
    <>
      <Box
        sx={{
          height: '60vh',
          background: `url(${HeroImage}) no-repeat center center/cover`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          textAlign: 'center',
          padding: '0 20px',
        }}
      >
        
        <Typography variant="h2" gutterBottom>
          Welcome to Reviewmee
        </Typography>
        <Typography variant="h5" gutterBottom>
          Empowering Citizens. Enhancing Governance.
        </Typography>
        <Button variant="contained" color="primary" size="large" component={Link} to="/get-started" style={{ marginTop: '20px' }}>
          Get Started
        </Button>
      </Box>

      <Container>
       

        <Typography variant="h4" gutterBottom style={{ marginTop: '40px' }}>
          Latest Announcements
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                alt="Public Transportation Initiative"
                height="140"
                image={PublicTransportImage}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Public Transportation Initiative
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  The government is launching a new initiative to improve public transportation in urban areas. This project aims to reduce traffic congestion and promote sustainable commuting.
                </Typography>
              </CardContent>
              <Button size="small" color="primary" component={Link} to="/announcements/1">
                Learn More
              </Button>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                alt="Small Business Support Policy"
                height="140"
                image={SmallBusinessImage}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Small Business Support Policy
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  The government has announced a new policy to support small businesses affected by recent economic changes. Grants and low-interest loans will be available to eligible businesses.
                </Typography>
              </CardContent>
              <Button size="small" color="primary" component={Link} to="/announcements/2">
                Learn More
              </Button>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                alt="Environmental Protection Plan"
                height="140"
                image={EnvironmentalProtectionImage}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Environmental Protection Plan
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  A comprehensive plan to protect the environment has been rolled out. It includes measures to reduce pollution, conserve natural resources, and promote green energy solutions.
                </Typography>
              </CardContent>
              <Button size="small" color="primary" component={Link} to="/announcements/3">
                Learn More
              </Button>
            </Card>
          </Grid>
        </Grid>

        <Typography variant="h4" gutterBottom style={{ marginTop: '40px' }}>
          Resources for Senior Citizens
        </Typography>
        <Typography variant="body1" gutterBottom>
          Our dedicated section for senior citizens provides valuable resources, tips, and information to help them navigate government services and stay informed about community activities.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                alt="Healthcare Services"
                height="140"
                image={HealthcareImage}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Healthcare Services
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Information on accessing healthcare services, including details about free health check-ups, vaccinations, and senior care facilities.
                </Typography>
              </CardContent>
              <Button size="small" color="primary" component={Link} to="/resources/1">
                Learn More
              </Button>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                alt="Financial Assistance Programs"
                height="140"
                image={FinancialAssistanceImage}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Financial Assistance Programs
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Details on financial assistance programs available for senior citizens, including pensions, subsidies, and tax benefits.
                </Typography>
              </CardContent>
              <Button size="small" color="primary" component={Link} to="/resources/2">
                Learn More
              </Button>
            </Card>
          </Grid>
        </Grid>

        <Typography variant="h4" gutterBottom style={{ marginTop: '40px' }}>
          Key Features
        </Typography>
        <ul>
          <li>Service Requests and Tracking</li>
          <li>Community Reporting and Feedback</li>
          <li>Transparency and Accountability</li>
          <li>Public Consultation and Participation</li>
          <li>Resources for Senior Citizens</li>
        </ul>

        <Typography variant="h4" gutterBottom style={{ marginTop: '40px' }}>
          Benefits
        </Typography>
        <ul>
          <li><strong>Enhanced Civic Engagement:</strong> Encourages active participation of citizens in governance and community development.</li>
          <li><strong>Improved Service Delivery:</strong> Streamlines the process of addressing public service requests, leading to quicker resolutions.</li>
          <li><strong>Increased Transparency:</strong> Builds trust in government by making operations and decision-making processes more transparent.</li>
          <li><strong>Data-Driven Decision Making:</strong> Empowers government bodies with actionable insights derived from citizen feedback and data analytics.</li>
        </ul>

        <Typography variant="h4" gutterBottom style={{ marginTop: '40px' }}>
          Get Involved
        </Typography>
        <Typography variant="body1" gutterBottom>
          Join our community and participate in discussions, provide feedback on public services, and stay updated with the latest government announcements.
        </Typography>
        <Button variant="contained" color="secondary" size="large" component={Link} to="/join">
          Join Now
        </Button>
      </Container>
    </>
  );
};

export default Home;
