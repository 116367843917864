import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';
import image1 from '../images/anmt1.webp';
import image2 from '../images/anmt2.webp';
import image3 from '../images/anmt3.webp';

const Announcement = () => {
  const { id } = useParams();

  const announcement = {
    1: {
      title: 'Public Transportation Initiative',
      content: `
        The government is launching a new initiative to improve public transportation in urban areas.
        This project aims to reduce traffic congestion and promote sustainable commuting. Key features of the initiative include:
        
        - Expansion of bus and train networks to underserved areas.
        - Introduction of eco-friendly buses and trains to reduce carbon emissions.
        - Implementation of smart ticketing systems to streamline the commuting experience.
        - Development of dedicated lanes for buses and bicycles to encourage alternative modes of transport.
        - Public awareness campaigns to promote the use of public transportation.

        This initiative is expected to significantly improve the quality of life for urban residents and contribute to environmental sustainability.
      `,
      image: image1
    },
    2: {
      title: 'Small Business Support Policy',
      content: `
        The government has announced a new policy to support small businesses affected by recent economic changes.
        This policy includes a range of measures designed to provide financial relief and support to small business owners. Highlights of the policy include:

        - Grants and low-interest loans to help businesses recover from economic disruptions.
        - Tax relief measures to reduce the financial burden on small businesses.
        - Support for digital transformation to help businesses adapt to the changing market landscape.
        - Access to business advisory services and training programs to enhance business skills.
        - Initiatives to promote local businesses and encourage community support.

        This policy aims to revitalize the small business sector, create jobs, and stimulate economic growth in local communities.
      `,
      image: image2
    },
    3: {
      title: 'Environmental Protection Plan',
      content: `
        A comprehensive plan to protect the environment has been rolled out.
        The plan includes a series of measures aimed at reducing pollution, conserving natural resources, and promoting green energy solutions. Key components of the plan are:

        - Implementation of stricter regulations on industrial emissions and waste management.
        - Investment in renewable energy projects, such as solar and wind power.
        - Programs to promote energy efficiency and reduce carbon footprints in households and businesses.
        - Conservation initiatives to protect endangered species and natural habitats.
        - Public education campaigns to raise awareness about environmental issues and encourage sustainable practices.

        This plan represents a significant step towards a cleaner, greener future, ensuring the protection of our environment for generations to come.
      `,
      image: image3
    }
  };

  const data = announcement[id];

  return (
    <Container>
      <Typography variant="h4" textAlign={'center'} gutterBottom>
        {data.title}
      </Typography>
      {data.image && (
        <Box
          component="img"
          src={data.image}
          alt={data.title}
          sx={{ width: '100%', height: 'auto', marginBottom: 2 }}
        />
      )}
      <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
        {data.content}
      </Typography>
    </Container>
  );
};

export default Announcement;
