import React from 'react';
import { Container, Typography, Box, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
  return (
    <Box component="footer" sx={{ mt: 5, py: 3, bgcolor: 'black', color: 'white' }}>
      <Container maxWidth="lg">
        <Typography variant="body1" align="center">
          &copy; 2024 Reviewmee. All Rights Reserved.
        </Typography>
        <Typography variant="body2" align="center">
          Follow us on
        </Typography>
        <Box display="flex" justifyContent="center">
          <IconButton
            component="a"
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: 'white' }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            component="a"
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: 'white' }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            component="a"
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: 'white' }}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            component="a"
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: 'white' }}
          >
            <LinkedInIcon />
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
