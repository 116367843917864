import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import Discussion from '../components/Discussion';

const DiscussionsPage = () => {
  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Community Discussions
        </Typography>
        <Paper elevation={3} style={{ padding: '16px', marginBottom: '24px' }}>
          <Typography variant="h6" gutterBottom>
            Welcome to the Community Discussions!
          </Typography>
          <Typography variant="body1" paragraph>
            This section of Reviewmee is dedicated to fostering discussions among community members. Here, you can engage in meaningful conversations, ask questions, and share your experiences and knowledge.
          </Typography>
          <Typography variant="body1" paragraph>
            Whether you are a new user looking for tips or an experienced member willing to help others, this is the right place for you. Please ensure that your contributions are respectful and follow our community guidelines.
          </Typography>
          <Typography variant="body1" paragraph>
            Key Features:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                <strong>Real-time Updates:</strong> Stay up-to-date with the latest comments and discussions.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>User Profiles:</strong> View and interact with other community members.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Rich Text Support:</strong> Use formatting options to make your posts clear and engaging.
              </Typography>
            </li>
          </ul>
        </Paper>
      </Box>
      <Discussion />
    </Container>
  );
};

export default DiscussionsPage;
