import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import AboutHero from '../images/about_hero.webp'; // Add your hero image here

const About = () => {
  return (
    <Container>
      <Box
        sx={{
          height: '50vh',
          background: `url(${AboutHero}) no-repeat center center/cover`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          textAlign: 'center',
          padding: '0 20px',
          marginBottom: '40px',
        }}
      >
        <Typography variant="h2" gutterBottom>
          About Us
        </Typography>
      </Box>
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Our Mission
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to Reviewmee! Our mission is to foster community engagement through discussions and announcements.
          We believe in creating a platform where users can share their thoughts, ask questions, and stay informed about the latest updates.
        </Typography>
        <Typography variant="body1" paragraph>
          Our team consists of dedicated individuals passionate about technology and community building. Together, we strive to provide a user-friendly
          and secure platform for all our users.
        </Typography>
        <Typography variant="body1" paragraph>
          Thank you for being a part of our community!
        </Typography>
      </Box>
    </Container>
  );
};

export default About;
