import React, { useState, useEffect, useContext } from 'react';
import { Container, Typography, Button, Card, CardContent, CardActions, Avatar, Paper, TextField, CardMedia, Box } from '@mui/material';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { deepPurple } from '@mui/material/colors';

const PostManagementPage = () => {
  const { user } = useContext(AuthContext);
  const [posts, setPosts] = useState([]);
  const [editingPost, setEditingPost] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [comments, setComments] = useState({});

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/posts');
      setPosts(response.data);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const handleDelete = async (postId) => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('No auth token found');
        return;
      }

      console.log('Auth Token:', token); 

      await axios.delete(`http://localhost:5000/api/posts/${postId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPosts(posts.filter(post => post._id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('content', content);
      formData.append('author', user.id);
      if (image) {
        formData.append('image', image);
      }

      await axios.put(`http://localhost:5000/api/posts/${editingPost._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      setEditingPost(null);
      setTitle('');
      setContent('');
      setImage(null);
      fetchPosts();
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };

  const handleEdit = (post) => {
    setEditingPost(post);
    setTitle(post.title);
    setContent(post.content);
    setImage(null);
  };

  const handleCommentChange = (postId, value) => {
    setComments({
      ...comments,
      [postId]: value
    });
  };

  const handleComment = async (postId) => {
    try {
      await axios.post(`http://localhost:5000/api/posts/${postId}/comments`, {
        user: user?.name,
        text: comments[postId]
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
      });
      setComments({
        ...comments,
        [postId]: ''
      });
      fetchPosts();
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Manage Posts
      </Typography>
      {posts.map(post => (
        <Card key={post._id} style={{ marginBottom: '20px' }}>
          {post.image && (
            <CardMedia
              component="img"
              alt="Post image"
              height="140"
              image={`http://localhost:5000/uploads/${post.image}`}
              title="Post image"
            />
          )}
          <CardContent>
            <Typography variant="h5">{post.title}</Typography>
            <Typography variant="body2">{post.content}</Typography>
            <Typography variant="caption">
              By {post.author} on {new Date(post.date).toLocaleDateString()}
            </Typography>
            <Box marginTop={2}>
              {post.comments && post.comments.map(comment => (
                <Paper elevation={1} style={{ padding: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }} key={comment._id}>
                  <Avatar sx={{ bgcolor: deepPurple[500], marginRight: '10px' }}>
                    {comment.user[0]}
                  </Avatar>
                  <Box>
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                      {comment.user}
                    </Typography>
                    <Typography variant="body2">
                      {comment.text}
                    </Typography>
                  </Box>
                </Paper>
              ))}
            </Box>
          </CardContent>
          <CardActions style={{ justifyContent: 'space-between' }}>
            <Box width="100%">
              <TextField
                label="Comment"
                fullWidth
                margin="normal"
                value={comments[post._id] || ''}
                onChange={(e) => handleCommentChange(post._id, e.target.value)}
              />
              <Button size="small" style={{ color: '#fff', backgroundColor: '#000' }} onClick={() => handleComment(post._id)}>
                Add Comment
              </Button>
            </Box>
            {post.author === user?.name && (
              <Box>
                <Button size="small" style={{ color: '#fff', backgroundColor: '#000', marginRight: '10px', marginBottom: '10px' }} onClick={() => handleEdit(post)}>
                  Edit
                </Button>
                <Button size="small" style={{ color: '#fff', backgroundColor: '#000' }} onClick={() => handleDelete(post._id)}>
                  Delete
                </Button>
              </Box>
            )}
          </CardActions>
        </Card>
      ))}
      {editingPost && (
        <Box marginTop={4}>
          <Typography variant="h5">Edit Post</Typography>
          <TextField
            label="Title"
            fullWidth
            margin="normal"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            label="Content"
            fullWidth
            multiline
            rows={4}
            margin="normal"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
            style={{ margin: '20px 0' }}
          />
          <Button variant="contained" style={{ color: '#fff', backgroundColor: '#000', marginRight: '10px' }} onClick={handleUpdate}>
            Update Post
          </Button>
          <Button variant="contained" style={{ color: '#fff', backgroundColor: '#000' }} onClick={() => setEditingPost(null)}>
            Cancel
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default PostManagementPage;
