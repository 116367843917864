import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import axios from 'axios';
import RegisterHero from '../images/register.webp'; 

const RegisterPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/api/auth/register', { name, email, password });
      setMessage('Registration successful! Please check your email for confirmation.');
    } catch (error) {
      const errorMsg = error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg
        : 'Registration failed';
      setMessage(errorMsg);
    }
  };

  return (
    <Container>
      <Box
        sx={{
          height: '50vh',
          background: `url(${RegisterHero}) no-repeat center center/cover`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          textAlign: 'center',
          padding: '0 20px',
          marginBottom: '40px',
        }}
      >
      </Box>
      <Typography variant="h4" gutterBottom>
        Register
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label="Email"
          type="email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button type="submit" variant="contained" style={{ color: '#fff', backgroundColor: '#000' }}>
          Register
        </Button>
      </form>
      {message && <Typography style={{ marginTop: '20px' }}>{message}</Typography>}
    </Container>
  );
};

export default RegisterPage;
