import React, { useState, useContext } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import LoginHero from '../images/login.webp'; 

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/api/auth/login', { email, password });
      const { token } = response.data;
      login(token);
      setMessage('Login successful!');
      navigate('/');
    } catch (error) {
      const errorMsg = error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg
        : 'Invalid email or password';
      setMessage(errorMsg);
    }
  };

  return (
    <Container>
      <Box
        sx={{
          height: '50vh',
          background: `url(${LoginHero}) no-repeat center center/cover`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          textAlign: 'center',
          padding: '0 20px',
          marginBottom: '40px',
        }}
      >
        
      </Box>
      <Typography variant="h2" style={{color: '#000', textAlign: 'center'}} gutterBottom>
          Welcome Back
        </Typography>
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          type="email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" variant="contained" style={{ color: '#fff', backgroundColor: '#000' }}>
          Login
        </Button>
      </form>
      {message && <Typography style={{ marginTop: '20px' }}>{message}</Typography>}
      <Typography variant="body2" style={{ marginTop: '20px' }}>
        <Link to="/forgot-password" style={{ textDecoration: 'none', color: 'inherit' }}>
          Forgot Password?
        </Link>
      </Typography>
    </Container>
  );
};

export default LoginPage;
