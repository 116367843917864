import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box } from '@mui/material';
import ContactHero from '../images/contact_hero.webp'; 

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to server
    setSubmitted(true);
  };

  return (
    <Container>
      <Box
        sx={{
          height: '50vh',
          background: `url(${ContactHero}) no-repeat center center/cover`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          textAlign: 'center',
          padding: '0 20px',
          marginBottom: '40px',
        }}
      >
        <Typography variant="h2" gutterBottom>
          Contact Us
        </Typography>
      </Box>
      <Box my={4}>
        {submitted ? (
          <Typography variant="body1" paragraph>
            Thank you for reaching out! We will get back to you soon.
          </Typography>
        ) : (
          <form onSubmit={handleSubmit}>
            <TextField
              label="Name"
              fullWidth
              margin="normal"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <TextField
              label="Email"
              type="email"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              label="Message"
              fullWidth
              margin="normal"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              multiline
              rows={4}
            />
            <Button type="submit" variant="contained" style={{ color: '#fff', backgroundColor: '#000' }}>
              Send Message
            </Button>
          </form>
        )}
      </Box>
    </Container>
  );
};

export default Contact;
