import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography } from '@mui/material';

const Resource = () => {
  const { id } = useParams();

  // Fetch the resource details based on the id
  // For now, let's assume static data
  const resource = {
    1: {
      title: 'Healthcare Services',
      content: `
        Information on accessing healthcare services, including details about free health check-ups, vaccinations, and senior care facilities.

        - **Free Health Check-ups:** Senior citizens are entitled to free annual health check-ups at designated government hospitals and clinics. These check-ups cover a range of basic health screenings including blood pressure, cholesterol, and diabetes tests.
        
        - **Vaccinations:** The government provides free or subsidized vaccinations for senior citizens to protect against common illnesses such as influenza, pneumonia, and shingles.
        
        - **Senior Care Facilities:** Information about government-approved senior care facilities that offer comprehensive healthcare and support services. These facilities are equipped to handle various health conditions and provide a safe environment for senior citizens.
        
        - **Emergency Services:** Access to emergency healthcare services, including ambulance services and emergency response teams, to ensure timely medical attention in case of an emergency.

        - **Mental Health Support:** Access to mental health services, including counseling and therapy sessions, aimed at addressing the mental health needs of senior citizens. Support groups and helplines are also available.

        - **Home Healthcare Services:** Information on home healthcare services for senior citizens who need medical care but prefer to stay at home. These services include nursing care, physical therapy, and home visits by doctors.
      `
    },
    2: {
      title: 'Financial Assistance Programs',
      content: `
        Details on financial assistance programs available for senior citizens, including pensions, subsidies, and tax benefits.

        - **Pensions:** Information about government pension schemes for senior citizens, including eligibility criteria, application procedures, and benefits. These pensions provide a steady income to support daily living expenses.
        
        - **Subsidies:** Various subsidies are available to help senior citizens reduce their cost of living. These include subsidies for utility bills, public transportation, and medical expenses.
        
        - **Tax Benefits:** Senior citizens are eligible for certain tax benefits and exemptions. Details on how to apply for these benefits, including the necessary documentation and deadlines, are provided.
        
        - **Grants and Loans:** Information on grants and low-interest loans available to senior citizens for purposes such as home renovations, healthcare expenses, and starting small businesses.
        
        - **Financial Counseling:** Access to free financial counseling services to help senior citizens manage their finances, plan for retirement, and make informed financial decisions.

        - **Housing Assistance:** Programs that provide financial support for housing, including rent assistance and home maintenance grants, to ensure safe and affordable living conditions for senior citizens.

        - **Employment Support:** Information on part-time job opportunities and volunteer programs tailored for senior citizens, including training and placement services to help them remain active and engaged in the community.
      `
    }
  };

  const data = resource[id];

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {data.title}
      </Typography>
      <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
        {data.content}
      </Typography>
    </Container>
  );
};

export default Resource;
